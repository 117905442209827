// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
import React from "react";

import { Link } from "gatsby";
import Layout from "../components/layout";
import PageInfo from "../components/pageinfo";

const Download = ({name, file}) =>
    <a href={"/bookcode/" + file} className="code-downloads__big-button"> 
        {name}
    </a>;

const CodeDownloadsSection = () => {
const downloads = Array.from(
        Array(19),
        (_, index) => [
            `Chapter ${index + 1}`,
            `ch${(index + 1).toString().padStart(2, "0")}-downloads.zip`
        ]
    );
    return <section id="code" className="home-page__code-downloads">
        <div className="wrapper--full-width">
            <div className="code-downloads__intro"> 
                <h1>Code Downloads</h1>
                <div>
                    (See also <Link style={{borderBottom: "1px solid"}} to="/bookcode/live/16/basic-SharedArrayBuffer-example.html">
                        the hosted <code>SharedArrayBuffer</code> example
                    </Link> from Chapter&nbsp;16)
                </div>
            </div>

            <div className="button--grid">
                <Download name="All" file="all-downloads.zip" />
            </div>
            <div className="button--grid">
                {downloads.map(([name, file]) => <Download key={file} name={name} file={file} />)}
            </div>
        </div> 
    </section>;
};

const CodeDownloadsPage = () => (
    <Layout>
        <PageInfo title="Code Downloads" />
        <CodeDownloadsSection />
    </Layout>
);

export default CodeDownloadsPage;
